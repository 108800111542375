import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AppGlobals} from "../../app.globals";
import { MastersAllService } from './masters-all.service';
import {Master} from "./data.items";

@Component({
    selector: 'app-masters-all',
    templateUrl: './masters-all.component.html',
    styleUrls: ['./masters-all.component.scss'],
    providers: [MastersAllService]
})
export class MastersAllComponent implements OnInit {
    currentPage = 1;
    itemPerPage = 1000;
    totalPage = 1;
    rows1: Master['data'][];
    rows2: Master['data'][];
    rows3: Master['data'][];

    constructor(
        private router: Router,
        private globals: AppGlobals,
        private dataService: MastersAllService,
    ) {
    }

    ngOnInit() {
        this.getAll(1);
        this.getAll(2);
        this.getAll(3);
    }

    // 목록
    getAll(masterGroup): void {
        const params = {
            master_group: masterGroup,
            sortby: ['master_no'],
            order: ['asc'],
            offset: (this.currentPage - 1) * this.itemPerPage,
            limit: this.itemPerPage,
            page_no: this.currentPage
        };

        this.dataService.getAll(params).subscribe(
            result => {
                if (masterGroup === 1) {
                    this.rows1 = result['data'];
                } else if (masterGroup === 2) {
                    this.rows2 = result['data'];
                } else if (masterGroup === 3) {
                    this.rows3 = result['data'];
                }
            }
        );
    }
}
