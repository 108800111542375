import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from '../app.globals';

@Component({
    selector: 'app-page',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit {

    elementType: string;
    qrGroup1: string;
    qrGroup2: string;
    qrGroup3: string;

    constructor(
        private router: Router,
        private globals: AppGlobals
    ) {
        this.elementType = 'url';
        this.qrGroup1 = 'http://gwmaster.co.kr/#/masters/group/1';
        this.qrGroup2 = 'http://gwmaster.co.kr/#/masters/group/2';
        this.qrGroup3 = 'http://gwmaster.co.kr/#/masters/group/3';
    }

    ngOnInit() {
    }

    routerLink(s): void {
        this.router.navigateByUrl(s);
    }
}
