import {Component, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {AppGlobals} from "../app.globals";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-page',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [DatePipe]
})
export class HomeComponent implements OnInit {

    isKiosk = false;
    mainVideoSrc: any;
    mobileMainVideoSrc: any;

    constructor(
        private router: Router,
        private globals: AppGlobals,
        private sanitizer: DomSanitizer
    ) {
        if (this.isKiosk === true) {
            this.mainVideoSrc = this.globals.serverUrl + '/files/video/video1.mp4';
        } else {
            const videoId = 'kRYd7u0yJPU';
            const videoSrc = 'https://www.youtube.com/embed/' + videoId + '?autoplay=1&loop=1&playlist=' + videoId;
            const mobileVideoSrc = 'https://www.youtube.com/embed/' + videoId;
            this.mainVideoSrc = this.sanitizer.bypassSecurityTrustHtml('<iframe width="1120" height="630" src="' + videoSrc + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            this.mobileMainVideoSrc = this.sanitizer.bypassSecurityTrustHtml('<iframe width="400" height="225" src="' + mobileVideoSrc + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        }
    }

    ngOnInit() {
        this.router.events.subscribe((val) => {
        // clearInterval(this.loopSearchIntervalId);
            clearTimeout(this.globals.autoPromotionVideoId);
        });
    }

    routerLink(s) {
        this.router.navigateByUrl(s);
    }
}
