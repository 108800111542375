import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AppGlobals} from '../../app.globals';
import {Master} from "./data.items";

@Injectable()
export class MastersAllService {

	constructor(
		private http: HttpClient,
		private globals: AppGlobals
	) {
	}

	public isCorrect: boolean;
	private url = this.globals.serverUrl;

	getAll(params): Observable<Master[]> {
		return this.http.get<Master[]>(this.url + '/masters', {params});
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
		console.error(error);

		return of(result as T);
		};
	}
}
