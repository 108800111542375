import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppGlobals } from './app.globals';

export class Item {
    menu_src: string;
    user_id: string;
}

@Injectable()
export class AuthGuard implements CanActivate {
    isPrintable: boolean = true;

    constructor(
        private http: HttpClient,
        private globals: AppGlobals,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
        if (sessionStorage.getItem('currentUser')) {
            let user = JSON.parse(sessionStorage.getItem('currentUser'));
            // console.log(user.user_id);
            // console.log(route.routeConfig.path);
            this.globals.userId = user.user_id;
            this.globals.userName = user.user_name;
            // logged in so return true
            // return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/mypage/signin']);
            return false;
        }
    }
}
