import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AppGlobals} from '../../app.globals';
import {catchError, tap} from "rxjs/operators";
import {Master, MasterPhoto} from "./data.items";

@Injectable()
export class MastersViewService {

	constructor(
		private http: HttpClient,
		private globals: AppGlobals
	) {
	}

	public isCorrect: boolean;
	private url = this.globals.serverUrl;

	getMasterInfoById(id): Observable<Master> {
		return this.http.get<Master>(this.url + '/masters/' + id);
	}

	getPhotoAll(masterId, params): Observable<MasterPhoto[]> {
        return this.http.get<MasterPhoto[]>(this.url + '/masters/' + masterId + '/photo', {params});
    }

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
		  console.error(error);
	
		  return of(result as T);
		};
	}
}
