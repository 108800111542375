import {Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute} from '@angular/router';
import { MastersViewService } from '../masters/view/masters-view.service';
import { Master } from "../masters/view/data.items";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    providers: [MastersViewService]
})

export class LayoutComponent implements OnInit, AfterViewInit {
    pageTitle = '';
    autoPromotionVideoId;
    isSelectMenu1: boolean = false;
    isSelectMenu2: boolean = false;
    isSelectMenu3: boolean = false;
    isSelectMenu4: boolean = false;
    isSelectMenu5: boolean = false;
    isSelectMenu6: boolean = false;
    isSelectMenu7: boolean = false;
    isSelectMenu8: boolean = false;
    masterData: Master['data'];
    collapse: boolean = false;

    @ViewChild('menuBar', {static: false}) menuBar: ElementRef;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dataService: MastersViewService,
    ) {
        this.router.events.subscribe((event: Event) => {            
            if (event instanceof NavigationEnd) {
                const url = event.url.split('/');
                if (url[1] === '') {
                    this.isSelectMenu1 = true;
                } else {
                    this.isSelectMenu1 = false;
                    if (url[2] === 'group') {
                        if (url[3] === '1') {
                            this.isSelectMenu6 = true;
                            this.isSelectMenu7 = false;
                            this.isSelectMenu8 = false;
                        } else if (url[3] === '2') {
                            this.isSelectMenu6 = false;
                            this.isSelectMenu7 = true;
                            this.isSelectMenu8 = false;
                        } else if (url[3] === '3') {
                            this.isSelectMenu6 = false;
                            this.isSelectMenu7 = false;
                            this.isSelectMenu8 = true;
                        }
                    }
                }
                if (url[2] === 'view') {
                    this.isSelectMenu1 = false;
                    this.isSelectMenu2 = false;
                    this.isSelectMenu3 = true;
                    this.isSelectMenu4 = false;
                    this.isSelectMenu5 = false;
                    this.isSelectMenu6 = false;
                    this.isSelectMenu7 = false;
                    this.isSelectMenu8 = false;

                    this.dataService.getMasterInfoById(url[3]).subscribe(
                        result => {
                            this.masterData = result['data'];
                            const masterGroup = this.masterData.master_group;

                            if (masterGroup == 1) {
                                this.isSelectMenu6 = true;
                            } else if (masterGroup == 2) {
                                this.isSelectMenu7 = true;
                            } else {
                                this.isSelectMenu8 = true;
                            }
                        },
                        error => console.log(error.error)
                    );
                }
            }
        });
    }

    ngOnInit() {
        switch (this.router.url) {
            case '/': 
                this.isSelectMenu1 = true;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/about': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = true;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/masters/all': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = true;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/board/notice': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = true;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/lecture/request': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = true;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/masters/group/1': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = true;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/masters/group/2': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = true;
                this.isSelectMenu8 = false;
            break;
            case '/masters/group/3': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = true;
            break;
        } 
    }

    ngAfterViewInit() {
    }

    routerLink(s) {
        this.collapse = false;
        switch (s) {
            case '/': 
                this.isSelectMenu1 = true;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/about': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = true;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/masters/all': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = true;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/board/notice': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = true;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/lecture/request': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = true;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/masters/group/1': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = true;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = false;
            break;
            case '/masters/group/2': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = true;
                this.isSelectMenu8 = false;
            break;
            case '/masters/group/3': 
                this.isSelectMenu1 = false;
                this.isSelectMenu2 = false;
                this.isSelectMenu3 = false;
                this.isSelectMenu4 = false;
                this.isSelectMenu5 = false;
                this.isSelectMenu6 = false;
                this.isSelectMenu7 = false;
                this.isSelectMenu8 = true;
            break;
        } 
        this.router.navigateByUrl(s);
    }
}
