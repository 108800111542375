import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppGlobals } from '../app.globals';
import { Item } from './board.items';
import { BoardService } from './board.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
    selector: 'app-page',
    templateUrl: './board-view.component.html',
    styleUrls: ['./board-view.component.scss'],
    providers: [ BoardService, { provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } } ]
})

export class BoardViewComponent implements OnInit {

    boardId = 'notice';
    articleId: number;
    
    articleData: Item['data'];
    atcSubject: string = '';
    atcContent: string = '';
    atcWriter: string = '';
    createdAt: string = '';
    atcViews: number = 0;
    attachedFiles = [];
    prVideo = false;
    fileSrcAll = [];
    iframeSrc: SafeUrl;
    prevNo: number = 0;
    nextNo: number = 0;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private globals: AppGlobals,
        private datePipe: DatePipe,
        private dataService: BoardService
    ) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(param => {
            this.articleId = Number(param['no']);
            this.getById(this.articleId);
        });
    }

    getById(id) {
        this.dataService.GetById(id).subscribe(
            result => {
                this.articleData = result['data'];
                this.atcSubject = this.articleData.atc_subject;
                this.atcContent = this.articleData.atc_content;
                this.atcWriter = this.articleData.user_name;
                this.createdAt = this.articleData.createdAt;
                this.atcViews = this.articleData.views;
                this.attachedFiles = result['fileData'];
                console.log(this.attachedFiles);
                this.prevNo = result['prevNo'];
                this.nextNo = result['nextNo'];
            },
            error => console.log(error.error)
        );
    }

    prev() {
        const url = 'board/notice/view/' + this.prevNo;
        this.router.navigateByUrl(url);
    }

    next() {
        const url = 'board/notice/view/' + this.nextNo;
        this.router.navigateByUrl(url);
    }

    goToList() {
        this.router.navigateByUrl('board/notice');
    }

    fileDownload(e, v){
        if(e.type=='click'){
            console.log(">> click fileDownload", v)
            let fileName = v.file_name;
            let nameSplit = fileName.split("/");
            // 예전 파일 형식
            if(nameSplit.length>1){
                fileName = nameSplit[1];
            }

            let filePath = "";
            let srcSplit = v.file_src.split("/");

            if(srcSplit.length>7){
                filePath = srcSplit[5]+"||"+srcSplit[6]+"||"+srcSplit[7];
            }else{
                filePath = srcSplit[5]+"||"+srcSplit[6];
            }

            // console.log("fileDownload", filePath, fileName)
            let url = this.globals.serverUrl + "/board/" + filePath + "/" + fileName + "/download";
            // console.log("conf", url)
            window.open(url, 'blank');
        }
    }
}
