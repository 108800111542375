import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

// Global Variables
import { AppGlobals } from './app.globals';

// Auth
import { AuthGuard } from './app.auth';

// Layout Components
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';

// Shared Services
import { SharedModule } from './structure/shared/shared.module';
import { MessageService } from './message.service';

// modules (ngx-bootstrap)
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { QRCodeModule } from 'angularx-qrcode';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// RECOMMENDED
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

const ROUTES = [
];

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        QRCodeModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (HttpLoaderFactory),
            deps: [HttpClient]
        }
        }),
    ],
    providers: [
        AppGlobals,
        AuthGuard,
        BsModalService,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
