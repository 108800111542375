import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../app.globals';
import { LectureService } from './lecture.service';
import { Item } from './lecture.items';

@Component({
    selector: 'app-page',
    templateUrl: './lecture.component.html',
    styleUrls: ['./lecture.component.scss'],
    providers: [ LectureService ]
})

export class LectureComponent implements OnInit {
    inputForm: FormGroup;
    lectureData: Item['data'];
    masterGroup = [
        {no: 1, name: '대한민국명장'},
        {no: 2, name: '국가품질명장'},
        {no: 3, name: '광주광역시명장'},
    ];
    masterList = [];
    selectedMasterName: string;

    constructor(
        private dataService: LectureService,
        private router: Router,
        private globals: AppGlobals,
        private fb: FormBuilder,
    ) {
        this.inputForm = fb.group({
            master_group: [''],
            master_no: [''],
            applicant_name: [''],
            applicant_company: [''],
            applicant_phone1: [''],
            applicant_phone2: [''],
            applicant_phone3: [''],
            question_title: [''],
            question_content: [''],
            is_agree: ['']
        });
    }

    ngOnInit() {
    }

    onGroupSelected(e): void {      
        const groupNo = e.target.value;
        this.dataService.loadMasters().subscribe(
            result => {
                this.masterList = result['group' + groupNo];
            }
        );
    }

    selectedMaster(e): void {
        const selectedValue = e.target.value;
        this.selectedMasterName = '';
        this.masterList.forEach(data => {
            if (data.master_no === selectedValue) {
                this.selectedMasterName = data.master_name;
            }
        });
    }

    Save() {
        const formData = this.inputForm.value;
        if (formData.is_agree === '') {
            alert('개인정보 수집/이용 동의를 체크하세요.');
            return false;
        }
        if (formData.is_agree === 'N') {
            alert('개인정보 수집/이용 동의를 하지 않을 경우 강의신청이 불가합니다.');
            return false;
        }
        if (formData.master_group === '') {
            alert('명장 그룹을 선택하세요.');
            document.getElementById('master_group').focus();
            return false;
        }
        if (formData.master_no === '') {
            alert('명장을 선택하세요.');
            document.getElementById('master_no').focus();
            return false;
        }
        if (formData.applicant_name === '') {
            alert('담당자명을 입력하세요.');
            document.getElementById('applicant_name').focus();
            return false;
        }
        if (formData.applicant_company === '') {
            alert('소속을 입력하세요.');
            document.getElementById('applicant_company').focus();
            return false;
        }
        if (formData.applicant_phone1 === '') {
            alert('연락처를 입력해주세요.');
            document.getElementById('applicant_phone1').focus();
            return false;
        }
        if (formData.applicant_phone2 === '') {
            alert('연락처를 입력해주세요.');
            document.getElementById('applicant_phone2').focus();
            return false;
        }
        if (formData.applicant_phone3 === '') {
            alert('연락처를 입력해주세요.');
            document.getElementById('applicant_phone3').focus();
            return false;
        }
        formData.master_group = formData.master_group * 1;
        formData.master_no = formData.master_no * 1;
        formData.master_name = this.selectedMasterName;
        formData.applicant_phone = formData.applicant_phone1 + '-' + formData.applicant_phone2 + '-' + formData.applicant_phone3;
        
        this.Create(formData);
    }

    cancel() {
        this.inputForm.reset();
        this.router.navigateByUrl('/');
    }

    Create(formData): void {
        this.dataService.create(formData)
            .subscribe(
                data => {
                    if (data.result === 'success') {
                        alert('강의신청이 등록되었습니다!');
                        this.inputForm.reset();
                        this.router.navigateByUrl('/');
                    } else {
                        console.log(data.errorMessage);
                    }
                },
                error => console.log(error as any)
            );
    }
}
