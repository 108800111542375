import {Component, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {AppGlobals} from "../../app.globals";
import { MastersGroup1Service } from './masters-group1.service';
import {Master} from "./data.items";

@Component({
    selector: 'app-masters-group1',
    templateUrl: './masters-group1.component.html',
    styleUrls: ['./masters-group1.component.scss'],
    providers: [MastersGroup1Service]
})
export class MastersGroup1Component implements OnInit {

    isKiosk = false;
    mainVideoSrc: any;
    mobileMainVideoSrc: any;
    
    currentPage = 1;
    itemPerPage = 1000;
    totalPage = 1;
    rows: Master['data'][];
    
    constructor(
        private router: Router,
        private globals: AppGlobals,
        private sanitizer: DomSanitizer,
        private dataService: MastersGroup1Service,
    ) {
        if (this.isKiosk === true) {
            this.mainVideoSrc = this.globals.serverUrl + '/files/video/video2.mp4';
        } else {
            const videoId = 'lr6C4rZt7KU';
            const videoSrc = 'https://www.youtube.com/embed/' + videoId + '?autoplay=1&loop=1&playlist=' + videoId;
            const mobileVideoSrc = 'https://www.youtube.com/embed/' + videoId;
            this.mainVideoSrc = this.sanitizer.bypassSecurityTrustHtml('<iframe width="800" height="450" src="' + videoSrc + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            this.mobileMainVideoSrc = this.sanitizer.bypassSecurityTrustHtml('<iframe width="400" height="225" src="' + mobileVideoSrc + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        }
    }

    ngOnInit() {
        this.getAll(1);
    }

    // 목록
    getAll(masterGroup): void {
        const params = {
            master_group: masterGroup,
            sortby: ['master_no'],
            order: ['asc'],
            offset: (this.currentPage - 1) * this.itemPerPage,
            limit: this.itemPerPage,
            page_no: this.currentPage
        };

        this.dataService.getAll(params).subscribe(
            result => {
                this.rows = result['data'];
            }
        );
    }
}
