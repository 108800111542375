import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable()
export class AppGlobals {
    constructor(private router: Router
    ) { }

    // public serverUrl = 'http://localhost:8071';
    public serverUrl = 'http://go.gwmaster.co.kr';

    public userId = '';
    public userName = '';
    public menuSrc = '';
    public menuName = '';
    public subMenu = [];
    public panelTitle = '';
    public isDevIP = false;

    public offset = 0;

    public configs = null;
    public tDate: string = this.convertDate();

    // 그리드 높이
    public gridHeight: number = window.innerHeight - 360;

    // 그리드 메세지-
    public datatableMessages = { emptyMessage: '<div class="no-data">검색된 데이터가 없습니다.</div>', totalMessage: 'total' };

    // 권한없음 메세지
    public isNotExecutable = '실행 권한이 없습니다.';
    public isNotPrintable = '인쇄 권한이 없습니다.';

    // 홍보 영상 자동 대기 시간
    public playPromotionVideoDelayTime: number = 1000 * 60 * 10;
    // public playPromotionVideoDelayTime: number = 5000;
    autoPromotionVideoId;


    convertDate() {
        const t = new Date();
        const y = t.getFullYear();
        const m = t.getMonth() + 1;
        const d = t.getDate();

        return y + '-' + this.numberToString(m) + '-' + this.numberToString(d);
    }

    numberToString(n) {
        let str = '';
        if (n < 10) {
            str = '0' + n;
        } else {
            str = String(n);
        }
        return str;
    }

    addComma(n) {
        if (n < 1000) {
            return n;
        }
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    removeComma(n) {
        if ( !n ) {
            return 0;
        }
        if (n < 1000) {
            return n;
        }
        return n.replace(/[^\d.-]/g, '') * 1;
    }

    autoPlayPromotionVideo() {
        this.autoPromotionVideoId = setTimeout(() => {
            this.router.navigate(['promotion'], {queryParams: {isAutoMove: true}})
        }, this.playPromotionVideoDelayTime);
    }

}
