import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from '../app.globals';
import { Item } from './board.items';
import { BoardService } from './board.service';
import { DatePipe } from '@angular/common';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
    selector: 'app-page',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.scss'],
    providers: [ BoardService ]
})

export class BoardComponent implements OnInit {

    boardId = 'notice';
    selectSize = 10;
    currentPage = 1;
    itemPerPage = 10;
    totalItems = 0;
    totalPage = 1;

    rows: Item['data'][];

    constructor(
        private router: Router,
        private globals: AppGlobals,
        private datePipe: DatePipe,
        private dataService: BoardService
    ) {
    }

    ngOnInit() {
        this.getAll();
    }

    // 목록
    getAll(): void {

        const params = {
            sortby: ['master_name'],
            order: ['desc'],
            offset: (this.currentPage - 1) * this.itemPerPage,
            limit: this.itemPerPage,
            page_no: this.currentPage
        };

        this.dataService.GetAll(this.boardId, params).subscribe(
            result => {
                this.rows = result['data'];
                this.totalItems = result['page']['totalItems'];
                this.totalPage = result['page']['totalPages'];
            }
        );
    }

    pageChanged(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.getAll();
    }

    routerLink(s): void {
        const url = 'board/notice/view/' + s;
        this.router.navigateByUrl(url);
    }
}
