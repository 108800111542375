import {Component, OnInit, TemplateRef} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { MastersViewService } from './masters-view.service';
import { Master, MasterPhoto } from "./data.items";

@Component({
    selector: 'app-masters-view',
    templateUrl: './masters-view.component.html',
    styleUrls: ['./masters-view.component.scss'],
    providers: [MastersViewService]
})
export class MastersViewComponent implements OnInit {
    masterGroup: number;
    prevNo: number;
    nextNo: number;
    modalRef: BsModalRef;

    masterData: Master['data'];
    masterId: number = 0;
    masterName: string = '';
    masterProfileImage: string;
    masterBriefIntroduction = [];
    masterHistory = [];
    masterStory: string = '';
    offset = 0;
    currentPage = 1;
    itemPerPage = 20;
    totalItemCnt = 0;
    totalPage = 1;
    photoList = [];
    masterPhotos = [];
    qrCode: string = '';
    photoViewImage: string = '';
    videoViewSrc: any = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,  
        private dataService: MastersViewService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(param => {
            this.masterId = Number(param['no']);
            this.qrCode = 'http://gwmaster.co.kr/#/masters/view/' + this.masterId;
            this.getById(this.masterId);
            this.getPhotoAll(this.masterId);
        });
    }

    getById(id) {
        this.dataService.getMasterInfoById(id).subscribe(
            result => {
                this.masterData = result['data'];
                this.masterId = this.masterData.master_no;
                this.masterName = this.masterData.master_name;
                this.masterGroup = this.masterData.master_group;
                this.masterProfileImage = this.masterData.master_profile_img;
                this.masterBriefIntroduction = this.masterData.brief_introduction.split('\n');
                this.masterHistory = this.masterData.master_history.split('\n');
                this.masterStory = this.masterData.master_story;
                this.prevNo = this.masterData.prev_no;
                this.nextNo = this.masterData.next_no;
            },
            error => console.log(error.error)
        );
    }

    getPhotoAll(masterId): void {
        this.photoList = [];
        const params = {
            sortby: ['photo_id'],
            order: ['asc'],
            offset: (this.currentPage - 1) * 5,
            limit: 5,
            page_no: 1 //this.currentPage
        };
        this.dataService.getPhotoAll(masterId, params).subscribe(
            result => {
                this.masterPhotos = result['data'];
                // this.totalPage = result['page']['totalPages'];
            }
        );
    }

    prev() {
        const url = '/masters/view/' + this.prevNo
        this.router.navigateByUrl(url);
    }

    next() {
        const url = '/masters/view/' + this.nextNo
        this.router.navigateByUrl(url);
    }

    openPhotoModal(photoSrc, template: TemplateRef<any>) {
        console.log(photoSrc);
        this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
        this.photoViewImage = photoSrc;
    }

    openVideoModal(photoSrc, template: TemplateRef<any>) {
        console.log(this.videoViewSrc);
        this.videoViewSrc = this.sanitizer.bypassSecurityTrustHtml(photoSrc);
        this.modalRef = this.modalService.show(template);
    }

    checkGroup() {
        return 'group' + this.masterGroup;
    }
}
